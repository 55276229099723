  <template>
  <navbar></navbar>
  <template>
    <div>
    <transition name="slide-down">
      <div v-if="showSuccessPopup" class="success-popup">{{ msgDelet }}</div>
    </transition>
    <!-- Your other template content -->
  </div>
</template>
  <div>
    <main class="page-content">
      <div class="account-page-area">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 account_border-right sideBar-lg-3">
              <sideMenuVendor></sideMenuVendor>
            </div>
            <div class="col-lg-9 sideBar-lg-9">
              <div class="card-container" v-if="!loading">
                <!-- vin search -->
                <div class="vinDecoder card car-top-gb" v-if="vehiclDetailList.length === 0">
                  <Form @submit="onVinSearch" :validation-schema="schemaVin" v-slot="{ errors }">
                    <div class="align-items-center vinDecoderCard">
                      <div class="vin-head-wrap">
                        <h4 class="comm-title mb-0">
                          Vehicle Identification Number
                        </h4>
                      </div>
                      <div class="row">
                        <div class="vin-whole-wrap">
                          <div class="vin-search-wrap">
                            <div class="veh-vin">
                              <Field name="VIN" type="text" placeholder="Enter VIN" class="form-control"
                                :class="{ 'is-invalid': errors.VIN }" :disabled="loading" />
                              <div class="invalid-feedback">
                                {{ errors.VIN }}
                              </div>
                            </div>
                            <button class="btn btn-primary btnVinResearch" :disabled="loading">
                              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                              <span class="">Search</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div class="text-center vin-not-found" v-if="isVinValid == true">
                    <p>{{ invalidVinMsg }}</p>
                  </div>
                </div>

                <!-- vin information -->
                <div v-else-if="vehiclDetailList">
                  <div>
                    <div class="progress-bar-custom tabs-parent">
                      <div v-for="(tab, index) in tabs" :key="index" :class="{
                          active: activeTab === index,
                          visited: index < activeTab,
                        }" @click="activeTab = index" class="progress-step-custom tab-child"
                        :style="{ width: stepWidth + '%' }">
                        <div class="tab-message">{{ tab }}</div>
                      </div>
                    </div>
                    <div class="tab-content">
                      <div v-show="activeTab === 0" class="tab-content-wrapper">
                        <!--VIN Informations -->
                        <div class="card-container2 vinDecodedDetails">
                       
                          <label for="vin information" class="header-title-m">VIN Informations</label>
                          <div v-if="vinInfo.vinProcessed">
                            <div class="row">
                              <div class="col-md-6 cust-bot-space">
                                <label for="vinProcessed">VIN</label>
                                <input disabled class="form-control" type="text" v-model="vinInfo.vinProcessed" />
                              </div>
                              <div class="col-md-6 cust-bot-space">
                                <label for="year">Year of make</label>
                                <input class="form-control" type="number" v-model="vinInfo.year" />
                              </div>
                              <div class="col-md-6 cust-bot-space">
                                <label for="make">Make</label>
                                <input class="form-control" type="text" v-model="vinInfo.make" />
                              </div>
                              <div class="col-md-6 cust-bot-space">
                                <label for="model">Model</label>
                                <input class="form-control" type="text" v-model="vinInfo.model" />
                              </div>
                            </div>
                          </div>

                          <div v-if="vehicleInfo">
                            <div class="row">
                              <div class="col-md-6 cust-bot-space">
                                <label for="trim">Trim</label>
                                <input class="form-control" type="text" v-model="vehicleInfo[0].trim" />
                              </div>
                              <div class="col-md-6 cust-bot-space">
                                <label for="driveType">Drive train</label>
                                <input class="form-control" type="text" v-model="vehicleInfo[0].driveType" />
                              </div>
                              <div class="col-md-6 cust-bot-space">
                                <label for="bodyType">Body type</label>
                                <input class="form-control" type="text" v-model="vehicleInfo[0].segment[0]" />
                              </div>

                              <div class="col-md-6 cust-bot-space">
                                <label for="doors">No Of Doors</label>
                                <input class="form-control" type="number" v-model="vehicleInfo[0].doors" />
                              </div>

                              <div class="col-md-6 cust-bot-space">
                                <label for="sellingPrice">
                                  Auction start price</label>
                                <input class="form-control" type="number" v-model="auctionStartPrice" />
                              </div>
                              <div class="col-md-6 cust-bot-space">
                                <label for="sellingPrice">Selling price</label>
                                <input class="form-control" type="number" v-model="vehicleSellingPrice" />
                              </div>
                              <div class="col-md-6 cust-bot-space">
                                <label for="Registration">Mileage</label>
                                <input class="form-control" type="number" v-model="kmClocked" />
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <p>No data available</p>
                          </div>
                        </div>

                        <div class="bottom-btn-list">
                          <button class="btn-next-tab" @click="handleDelet()">
                            Delete
                          </button>
                          <button class="btn-next-tab" @click="nextTab(1)">
                            Next
                          </button>
                          <button :disabled="saveBtnLoader" class="btn-save-data" @click="saveTabData(0)">
                            <span v-show="saveBtnLoader" class="spinner-border spinner-border-sm">
                            </span><span class="">Save</span>
                          </button>
                        </div>
                      </div>

                      <div v-show="activeTab === 1" class="tab-content-wrapper">
                        <!-- Content for Tab 2 -->

                        <div class="vinDecodedFeatures">
                          <label for="Featuers" class="header-title-m">Features</label>

                          <div v-if="features.length !== 0">
                            <div class="row" id="features-content-Id">
                              <div class="col-md-6" style="display: none" v-for="(value, index) in features"
                                :key="index">
                                <div class="cust-bot-space">
                                  <label class="feature-label">{{
                                    value.description
                                    }}</label>
                                  <input type="text" class="form-control" v-model="value.name" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="groupedInteriorColorsData.length !== 0" id="interiorId" class="sectionInterior"
                            ref="sectionInterior">
                            <label for="ext-color">Interior Colors</label>

                            <div class="row">
                              <div class="col-md-6" v-for="(
                                  value, title
                                ) in groupedInteriorColorsData" :key="title">
                                <div class="">
                                  <input type="text" class="form-control" v-model="value.description" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div v-if="groupedExteriorColorsData.length !== 0" id="exteriorId">
                            <label for="ext-color">Exterior Colors</label>
                            <div class="row">
                              <div class="col-md-6" v-for="(
                                  value, title
                                ) in groupedExteriorColorsData" :key="title">
                                <div class="">
                                  <input type="text" class="form-control" v-model="value.description" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <p>No data available</p>
                          </div>
                        </div>
                        <div class="bottom-btn-list">
                          <button class="btn-prev-tab" @click="prevTab()">
                            Previous
                          </button>
                          <button class="btn-next-tab" @click="nextTab(2)">
                            Next
                          </button>
                          <button :disabled="saveBtnLoader" class="btn-save-data" @click="saveTabData(1)">
                            <span v-show="saveBtnLoader" class="spinner-border spinner-border-sm">
                            </span><span class="">Save</span>
                          </button>
                        </div>
                      </div>

                      <div v-show="activeTab === 2" class="tab-content-wrapper">
                        <!-- Content for Tab 3 -->

                        <div class="techspecification">
                          <label for="tech-specification" class="header-title-m">Technical Specifications</label>
                          <div v-if="techSpecs!=undefined && techSpecs.length !== 0">
                            <div class="row" id="tech-content-Id">
                              <div class="col-md-6" style="display: none" v-for="(value, index) in techSpecs"
                                :key="index">
                                <div class="cust-bot-space">
                                  <label class="tech-label">{{
                                    value.description
                                    }}</label>
                                  <input type="text" class="form-control" v-model="value.name" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <p>No data available</p>
                          </div>
                        </div>

                        <div class="bottom-btn-list">
                          <button class="btn-prev-tab" @click="prevTab()">
                            Previous
                          </button>
                          <button class="btn-next-tab" @click="nextTab(3)">
                            Next
                          </button>
                          <button :disabled="saveBtnLoader" class="btn-save-data" @click="saveTabData(2)">
                            <span v-show="saveBtnLoader" class="spinner-border spinner-border-sm">
                            </span><span class="">Save</span>
                          </button>
                        </div>
                      </div>
                      <div v-show="activeTab === 3" class="tab-content-wrapper">
                        <!-- Content for Tab 3 -->

                        <div class="">
                          <label for="vehicle-cond" class="header-title-m">Vehicle Conditions</label>
                          <div class="vehDetails card-cust-space">
                            <div ref="sectionCond" style="display: none" id="conditionId" class="sectionCond"></div>

                            <div class="row vech-condit">
                              <div class="label-container col-md-4 cust-bot-space">
                                <div class="sub-label-container">
                                  <label for="structuralParts">Structural parts</label>
                                </div>

                                <div class="clickable-label-container">
                                  <div class="">
                                    <select name=" " class="form-control" id="" v-model="selectedOptionStructural"
                                      @change="selectOptionStructural()">
                                      <option value="">Please Select</option>
                                      <option value="Normal">Normal</option>
                                      <option value="Repaired">Repaired</option>
                                      <option value="Damaged">Damaged</option>
                                      <option value="Altered">Altered</option>
                                    </select>
                                  </div>
                                  <input name="structuralPartsImage" type="file" class="vehicleUploadImage"
                                    @change="onChangeOptionStructural" :key="InputKeyStructural" />
                                </div>
                                <div class="test-run">
                                  <a class="hide-on-run">Please click to mark damages</a>
                                  <MarkerArea :imageUrl="optionStructural" @annotatedImage="updateImageUrlStructural" />
                                  <div v-if="optionStructuralError" style="color: red">
                                    {{ optionStructuralErrorMessage }}
                                  </div>
                                </div>
                              </div>

                              <div class="label-container col-md-4 cust-bot-space">
                                <div class="sub-label-container">
                                  <label for="structuralParts">Windshield</label>
                                </div>
                                <div class="clickable-label-container">
                                  <div class="">
                                    <select name=" " class="form-control" id="" v-model="selectedOptionWindshield"
                                      @change="selectOptionWindshield()">
                                      <option value="">Please Select</option>

                                      <option value="Normal">Normal</option>
                                      <option value="Chipped(Passenger side)">
                                        Chipped(Passenger side)
                                      </option>
                                      <option value="Cracked">Cracked</option>
                                      <option value="Chipped(Driver side)">
                                        Chipped(Driver side)
                                      </option>
                                    </select>
                                  </div>
                                  <input name="WindshieldImage" type="file" class="vehicleUploadImage"
                                    @change="onChangeOptionWindshield" :key="InputKeyWindshield" />
                                </div>
                                <div class="test-run">
                                  <a class="hide-on-run">Please click to mark damages</a>
                                  <MarkerArea :imageUrl="optionWindshield" @annotatedImage="updateImageUrlWindshield" />
                                  <div v-if="optionWindshieldError" style="color: red">
                                    {{ optionWindshieldErrorMessage }}
                                  </div>
                                </div>
                              </div>

                              <div class="label-container col-md-4 cust-bot-space">
                                <div class="sub-label-container">
                                  <label for="structuralParts">Tiers</label>
                                </div>
                                <div class="clickable-label-container">
                                  <div class="">
                                    <select name=" " class="form-control" id="" v-model="selectedOptionTiers"
                                      @change="selectOptionTiers()">
                                      <option value="">Please Select</option>
                                      <option value="Need 1 Tire">
                                        Need 1 Tire
                                      </option>
                                      <option value="Need 2 Tires">
                                        Need 2 Tires
                                      </option>
                                      <option value="Need 3 Tires">
                                        Need 3 Tires
                                      </option>
                                      <option value="Good">Good</option>
                                    </select>
                                  </div>
                                  <input name="TiersImage" type="file" class="vehicleUploadImage"
                                    @change="onChangeOptionTiers" :key="InputKeyTiers" />
                                </div>

                                <div class="test-run">
                                  <a class="hide-on-run">Please click to mark damages</a>
                                  <MarkerArea :imageUrl="optionTiers" @annotatedImage="updateImageUrlTiers" />

                                  <div v-if="optionTiersError" style="color: red">
                                    {{ optionTiersErrorMessage }}
                                  </div>
                                </div>
                              </div>

                              <div class="label-container col-md-4 cust-bot-space">
                                <div class="sub-label-container">
                                  <label for="structuralParts">Airbags</label>
                                </div>
                                <div class="clickable-label-container">
                                  <div class="">
                                    <select name=" " class="form-control" id="" v-model="selectedOptionAirbags"
                                      @change="selectOptionAirbags()">
                                      <option value="">Please Select</option>
                                      <option value="Normal">Normal</option>
                                      <option value="Inoperable">
                                        Inoperable
                                      </option>
                                      <option value="Missing">Missing</option>
                                    </select>
                                  </div>
                                  <input name="AirbagsImage" type="file" class="vehicleUploadImage"
                                    @change="onChangeOptionAirbags" :key="InputKeyAirbags" />
                                </div>
                                <div class="test-run">
                                  <a class="hide-on-run">Please click to mark damages</a>

                                  <div class="test-run">
                                    <a class="hide-on-run">Please click to mark damages</a>
                                    <MarkerArea :imageUrl="optionAirbags" @annotatedImage="updateImageUrlAirbags" />
                                  </div>

                                  <div v-if="optionAirbagsError" style="color: red">
                                    {{ optionAirbagsErrorMessage }}
                                  </div>
                                </div>
                              </div>
                              <div class="label-container col-md-4 cust-bot-space">
                                <div class="sub-label-container">
                                  <label for="structuralParts">Airbag Light</label>
                                </div>
                                <div class="clickable-label-container">
                                  <div class="">
                                    <select name=" " class="form-control" id="" v-model="selectedOptionAirbagLight"
                                      @change="selectOptionAirbagLight()">
                                      <option value="">Please Select</option>
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </div>
                                  <input name="AirbagLightImage" type="file" class="vehicleUploadImage"
                                    @change="onChangeOptionAirbagLight" :key="InputKeyAirbagLight" />
                                </div>

                                <div class="test-run">
                                  <a class="hide-on-run">Please click to mark damages</a>
                                  <MarkerArea :imageUrl="optionAirbagLight"
                                    @annotatedImage="updateImageUrlAirbagLight" />

                                  <div v-if="optionAirbagLightError" style="color: red">
                                    {{ optionAirbagLightErrorMessage }}
                                  </div>
                                </div>
                              </div>

                              <div class="label-container col-md-4 cust-bot-space">
                                <div class="sub-label-container">
                                  <label for="structuralParts">Anti-lock Breaks</label>
                                </div>
                                <div class="clickable-label-container">
                                  <div class="">
                                    <select name=" " class="form-control" id="" v-model="selectedOptionAntiLock"
                                      @change="selectOptionAntiLock()">
                                      <option value="">Please Select</option>
                                      <option value="Normal">Normal</option>
                                      <option value="Inoperable">
                                        Inoperable
                                      </option>
                                    </select>
                                  </div>
                                  <input name="AntilockBreaksImage" type="file" class="vehicleUploadImage"
                                    @change="onChangeOptionAntiLock" :key="InputKeyAntiLock" />
                                </div>

                                <div class="test-run">
                                  <a class="hide-on-run">Please click to mark damages</a>
                                  <MarkerArea :imageUrl="optionAntiLock" @annotatedImage="updateImageUrlAntiLock" />

                                  <div v-if="optionAntiLockError" style="color: red">
                                    {{ optionAntiLockErrorMessage }}
                                  </div>
                                </div>
                              </div>
                              <div class="label-container col-md-4 cust-bot-space">
                                <div class="sub-label-container">
                                  <label for="structuralParts">Odometer</label>
                                </div>
                                <div class="clickable-label-container">
                                  <div class="">
                                    <select name=" " class="form-control" id="" v-model="selectedOptionOdometer"
                                      @change="selectOptionOdometer()">
                                      <option value="">Please Select</option>

                                      <option value="Normal">Normal</option>
                                      <option value="Replaced">Replaced</option>
                                      <option value="Rollback">Rollback</option>
                                    </select>
                                  </div>
                                  <input name="OdometerImage" type="file" class="vehicleUploadImage"
                                    @change="onChangeOptionOdometer" :key="InputKeyOdometer" />
                                </div>

                                <div class="test-run">
                                  <a class="hide-on-run">Please click to mark damages</a>
                                  <MarkerArea :imageUrl="optionOdometer" @annotatedImage="updateImageUrlSuspension" />

                                  <div v-if="optionOdometerError" style="color: red">
                                    {{ optionOdometerErrorMessage }}
                                  </div>
                                </div>
                              </div>

                              <div class="label-container col-md-4 cust-bot-space">
                                <div class="sub-label-container">
                                  <label for="structuralParts">Suspension</label>
                                </div>
                                <div class="clickable-label-container">
                                  <div class="">
                                    <select name=" " class="form-control" id="" v-model="selectedOptionSuspension"
                                      @change="selectOptionSuspension()">
                                      <option value="">Please Select</option>

                                      <option value="Normal">Normal</option>
                                      <option value="Replaced">Replaced</option>
                                      <option value="Modified">Modified</option>
                                    </select>
                                  </div>
                                  <input name="SuspensionImage" type="file" class="vehicleUploadImage"
                                    @change="onChangeOptionSuspension" :key="InputKeySuspension" />
                                </div>

                                <div class="test-run">
                                  <a class="hide-on-run">Please click to mark damages</a>
                                  <MarkerArea :imageUrl="optionSuspension" @annotatedImage="updateImageUrlSuspension" />
                                  <div v-if="optionSuspensionError" style="color: red">
                                    {{ optionSuspensionErrorMessage }}
                                  </div>
                                </div>
                              </div>
                              <div class="label-container col-md-4 cust-bot-space">
                                <div class="sub-label-container">
                                  <label for="structuralParts">Emission Equiment</label>
                                </div>
                                <div class="clickable-label-container">
                                  <div class="">
                                    <select name=" " class="form-control" id="" v-model="selectedOptionEmission"
                                      @change="selectOptionEmission()">
                                      <option value="">Please Select</option>
                                      <option value="Normal">Normal</option>
                                      <option value="Modified">Modified</option>
                                      <option value="Missing Parts">
                                        Missing Parts
                                      </option>
                                    </select>
                                  </div>
                                  <input name="EmissionImage" type="file" class="vehicleUploadImage"
                                    @change="onChangeOptionEmission" :key="InputKeyEmission" />
                                </div>

                                <div class="test-run">
                                  <a class="hide-on-run">Please click to mark damages</a>
                                  <MarkerArea :imageUrl="optionEmission" @annotatedImage="updateImageUrlEmission" />
                                  <div v-if="optionEmissionError" style="color: red">
                                    {{ optionEmissionErrorMessage }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="bottom-btn-list">
                          
                          <button class="btn-prev-tab" @click="prevTab()">
                            Previous
                          </button>

                          <button class="btn-next-tab" @click="nextTab(4)">
                            Next
                          </button>
                          
                          <button :disabled="saveBtnLoader" class="btn-save-data" @click="saveTabData(3)">
                            <span v-show="saveBtnLoader" class="spinner-border spinner-border-sm">
                            </span><span class="">Save</span>
                          </button>
                        </div>
                      </div>

                      <div v-show="activeTab === 4" class="tab-content-wrapper">
                        <!-- Content for Tab 3 -->

                        <div class="vinDecodedDetailsImage">
                          <label for="vehicle-image" class="header-title-m">Vehicle Images</label>
                          <div class="vehicleImages card-cust-space">
                            <div ref="sectionVeh" class="sectionVeh" id="allImagesId"></div>

                            <div class="row">
                              <div class="col-md-12" style="display: inherit">
                                <div>
                                  <label for="image-input">Upload Images:
                                  </label>
                                  <input type="file" id="image-input" ref="imageInput" @change="handleImageUpload"
                                    multiple accept="image/jpeg" />
                                  <label v-if="images.length">Preview</label>
                                  <div v-if="images.length" class="row">
                                    <div v-for="(image, index) in images" :key="index" class="image-preview col-md-4">
                                      <img :src="image" alt="Image Preview" />

                                      <div class="image-actions">
                                        <button @click="handleImageDelete(index)">
                                          <i class="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="erroMessage" v-if="imageError">
                                    {{ imageErrorMessage }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="bottom-btn-list">
                          <button class="btn-prev-tab" @click="prevTab()">
                            Previous
                          </button>
                          <button class="btn-next-tab" @click="nextTab(5)">
                            Next
                          </button>
                          <button :disabled="saveBtnLoader" class="btn-save-data" @click="saveTabData(4)">
                            <span v-show="saveBtnLoader" class="spinner-border spinner-border-sm">
                            </span><span class="">Save</span>
                          </button>
                        </div>
                      </div>
                      <div v-show="activeTab === 5" class="tab-content-wrapper">
                        <!-- Content for Tab 3 -->

                        <div class="dealernote">
                          <label for="dealer-note" class="header-title-m">Auction Slots</label>
                          <br />
                          <select name="dateTimeForAuctionSlot" id="auctionSlotId" class="auctionSlot form-control"
                            v-model="selectedSlotId">
                            <option value="" disabled selected>
                              Select a auction slot
                            </option>
                            <option v-for="auctionSlot in auctionSlotsList" :key="auctionSlot.AuctionSlotId"
                              :value="auctionSlot.AuctionSlotId">
                              {{ auctionSlot.auctionSlotName }}
                            </option>
                          </select>
                          <br />
                          <label for="dealer-note" class="header-title-m">Dealer Note</label>

                          <div class="dealerNote card-cust-space">
                            <div ref="sectionDealer" class="sectionDealer" id="dealerNoteId"></div>

                            <div class="row">
                              <div class="col-md-12">
                                <textarea v-model="dealerNotes" class="form-control dealerNoteTextArea" rows="5"
                                  placeholder="Enter your notes"></textarea>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="bottom-btn-list">
                          <button class="btn-prev-tab" @click="prevTab()">
                            Previous
                          </button>
                          <button :disabled="saveBtnLoader" class="btn-save-data" @click="saveTabData(5)">
                            <span v-show="saveBtnLoader" class="spinner-border spinner-border-sm"></span>
                            <span class="">Save</span>
                          </button>

                          <button class="btn btn-primary" @click="submitForm()">
                            Submit
                          </button>
                        </div>
                      </div>

                      <!-- Add more tab content sections as needed -->
                    </div>
                  </div>

                  <!-- <scrollToTopButton></scrollToTopButton> -->
                </div>
                <!-- confirm modal -->
                <div>
                  <div class="modal" v-if="modalAuctionSlot">
                    <div class="modal-content modal-lg">
                      <h2>Confirmation</h2>
                      <p>
                        On submitting, the vehicle will be added to the auction
                        slot you selected.
                      </p>
                      <div class="modal-buttons modal-btn-auction-slot">
                        <button :disabled="loadingAuctionSlot" class="btn btn-danger" @click="addToAuctionSlot">
                          <span v-show="loadingAuctionSlot" class="spinner-border spinner-border-sm"></span>
                          <span class="">Confirm</span>
                        </button>
                        <button class="btn btn-primary" @click="closeModal">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="text-center loader-for-empty" style="margin-top: 20%">
                <p>Loading .....</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
  // import { onMounted, onBeforeUnmount } from 'vue';
  import MarkerArea from "../../components/MarkerArea.vue";
  import sideMenuVendor from "@/components/sideMenuVendor.vue";
  import userService from "@/services/user.service";
  import Config from "../../config";
  import navbar from "@/components/navbar.vue";
  import { Form, Field } from "vee-validate";
  import * as Yup from "yup";
  import moment from "moment";


  export default {
    components: {
      sideMenuVendor,
      Form,
      Field,
      // NotFound,
      navbar,
      MarkerArea,
    },
    data() {
      const schema = Yup.object().shape({
        structuralParts: Yup.string().required("field is required"),
      });

      const schemaVin = Yup.object().shape({
        VIN: Yup.string()
          .required("VIN is required")
          .min(8, "VIN must have more than 8 characters."),
      });

      return {
        schema,
        schemaVin,
        vehiclDetailList: [],
        selectedOption: "",
        fileTypes: ["image/jpeg", "image/png"],
        loadingUpload: false,
        loading: false,
        vehicleSellingPrice: "",
        auctionStartPrice: "",
        vinInfo: [],
        vehicleInfo: [],
        features: [],
        exteriorColors: [],
        interiorColors: [],
        techSpecs: [],
        packages: [],
        optionCodeContent: [],
        groupedFeaturesData: [],
        groupedExteriorColorsData: [],
        groupedInteriorColorsData: [],
        groupedTechSpecsData: [],
        userinfo: [],
        imageList: [],
        isImageValid: true,
        optionStructural: "",
        optionWindshield: "",
        optionAirbags: "",
        optionAirbagLight: "",
        optionTiers: "",
        optionAntiLock: "",
        optionOdometer: "",
        optionSuspension: "",
        optionEmission: "",
        showSizeLimitError: false,
        optionStructuralError: false,
        optionWindshieldError: false,
        optionAirbagsError: false,
        optionAirbagLightError: false,
        optionTiersError: false,
        optionAntiLockError: false,
        optionOdometerError: false,
        optionSuspensionError: false,
        optionEmissionError: false,
        selectedOptionStructural: "",
        selectedOptionWindshield: "",
        selectedOptionAirbags: "",
        selectedOptionAirbagLight: "",
        msgDelet:"",
        selectedOptionTiers: "",
        selectedOptionAntiLock: "",
        selectedOptionOdometer: "",
        selectedOptionSuspension: "",
        selectedOptionEmission: "",
        dealerNotes: "",
        optionStructuralErrorMessage: "",
        optionWindshieldErrorMessage: "",
        optionAirbagsErrorMessage: "",
        optionAirbagLightErrorMessage: "",
        optionTiersErrorMessage: "",
        optionAntiLockErrorMessage: "",
        optionOdometerErrorMessage: "",
        optionSuspensionErrorMessage: "",
        optionEmissionErrorMessage: "",
        InputKeyStructural: "",
        InputKeyWindshield: "",
        InputKeyAirbags: "",
        InputKeyAirbagLight: "",
        InputKeyTiers: "",
        InputKeyAntiLock: "",
        InputKeyOdometer: "",
        InputKeyEmission: "",
        InputKeySuspension: "",
        urlKey: "",
        vehicleDetailsData: "",
        vehImagesList: [],
        images: [],
        imageError: false,
        imageErrorMessage: "",
        msgSuccess: "",
        msgDelete: "",
        vehicleRegistrationNumber: "",
        kmClocked: "",
        isVinValid: false,
        currentUrl: "",
        isMarkerImage: false,
        damgeImageUrl1: "",
        damgeImageUrl2: "",
        damgeImageUrl3: "",
        damgeImageUrl4: "",
        markerError1: "",
        markerError2: "",
        markerError3: "",
        markerError4: "",
        damgeImage1: "",
        damgeImage2: "",
        damgeImage3: "",
        damgeImage4: "",
        invalidVinMsg: "",
        //wizard
        activeTab: 0,
        tabs: ["1", "2", "3", "4", "5", "6"], // Add more tab names as needed
        tab1Data: "",
        tab2Data: "",
        tab3Data: "",
        tab4Data: "",
        tab5Data: "",
        tab6Data: "",
        lastTabIndex: 5, // Update this value if you add more tabs
        formData: {},
        stepWidth: 0,
        saveBtnLoader: false,
        isvinActive: 1, // isVinActive - vehicle will be available for auction  . isVinUsing - already user is using
        modalAuctionSlot: false,
        loadingAuctionSlot: false,
        auctionSlotsList: [],
        selectedSlotId: "",
        showSuccessPopup: false
      };
    },
    created() {
      this.userinfo = JSON.parse(localStorage.getItem("user"));
      if (typeof this.userinfo.sellerId == "undefined") {
        this.$router.push("/").catch(() => {
          "vehicleupload";
        });
      }

      this.urlKey = this.$route.query.vin;

      if (this.urlKey !== "" && typeof this.urlKey !== "undefined") {
        let vin = { VIN: this.urlKey };

        this.onVinSearch(vin);
      }
    },
    watch: {
      $route(to) {
        if (!to.href.includes("#") && !to.href.includes("?vin")) {
          if (to.href.includes("VehicleUpload")) {
            this.emptyVinData();
          }
        }
      },
    },

    mounted() {
      this.updateProgressBar();
    },

    methods: {
      formatDateTime(dateTime) {
        return moment(dateTime).format("MMMM Do YYYY, h:mm A");
      },
      addToAuctionSlot() {
        this.loadingAuctionSlot = true;
        this.onVehicleUploadSubmit(true);
      },
      closeModal() {
        this.modalAuctionSlot = false;
      },
      updateProgressBar() {
        this.stepWidth = (100 / this.tabs.length) * this.activeTab;
      },


      handleDelet() {
      userService
        .vehcleDelet(this.userinfo.sellerId, this.vehicleDetailsData.vehId)
        .then((response) => {
          this.msgSuccess = response.data.Message;
          this.showSuccessPopup = true;

          setTimeout(() => {
            this.showSuccessPopup = false;
            this.$router.push("/VehicleList");
          }, 2000);
        })
        .catch((e) => {
          console.log(e);
        });
    },

      prevTab() {
        if (this.activeTab > 0) {
          this.activeTab--;
          this.updateProgressBar();
        }
      },
      nextTab(index) {
        if (this.activeTab < this.lastTabIndex) {
          this.activeTab = index;
          this.updateProgressBar();
        }
      },

      validateTab(index) {
        var valid = this.isEmpty();

        switch (index) {
          case 0:
            if (valid.indexNumber == 0) {
              this.$toast.error("Please check all fields of Tab  1 ", {
                position: "top",
                duration: 3000,
                queue: true,
              });
              return false;
            }
            break;

          case 1:
            if (valid.indexNumber == 1) {
              this.$toast.error("Please check all fields of Tab  2 ", {
                position: "top",
                duration: 3000,
                queue: true,
              });
              return false;
            }
            break;

          case 2:
            if (valid.indexNumber == 2) {
              this.$toast.error("Please check all fields of Tab  3 ", {
                position: "top",
                duration: 3000,
                queue: true,
              });
              return false;
            }
            break;
          case 3:
            if (valid.indexNumber == 3) {
              this.$toast.error("Please check all fields of Tab  4", {
                position: "top",
                duration: 3000,
                queue: true,
              });
              return false;
            }
            break;
          case 4:
            if (valid.indexNumber == 4) {
              this.$toast.error("Please check all fields of Tab  5", {
                position: "top",
                duration: 3000,
                queue: true,
              });
              return false;
            }
            break;
          case 5:
            if (valid.indexNumber == 5) {
              this.$toast.error("Please check all fields of Tab  6 ", {
                position: "top",
                duration: 3000,
                queue: true,
              });
              return false;
            }
            break;

          // Add more cases for additional tabs

          default:
            break;
        }

        return true;
      },

      getParentDivOfMarkerJsLogo() {
        const elements = document.querySelectorAll(
          '[title="Powered by marker.js"]'
        );

        elements.forEach((element) => {
          const parentElement = element.parentNode;
          parentElement.style.display = "none";
        });
      },

      emptyVinData() {
        this.vehiclDetailList = [];
        this.selectedOption = "";
        this.features = [];
        this.vehImagesList = [];
        this.vehicleDetailsData = [];
        this.vehicleInfo = [];
        this.vehicleCondition = [];
        this.images = [];
        this.damgeImageUrl1 = "";
        this.damgeImageUrl2 = "";
        this.damgeImageUrl3 = "";
        this.damgeImageUrl4 = "";

        this.optionAirbags = "";
        this.optionAirbagLight = "";
        this.optionAntiLock = "";
        this.optionTiers = "";
        this.optionOdometer = "";
        this.optionWindshield = "";
        this.optionStructural = "";
        this.selectedOptionOdometer = "";

        this.selectedOptionWindshield = "";
        this.selectedOptionAntiLock = "";
        this.selectedOptionTiers = "";
        this.selectedOptionOdometer = "";
        this.selectedOptionAirbags = "";
        this.selectedOptionAirbagLight = "";
        this.selectedOptionStructural = "";

        this.vehicleImages = [];
        this.vehicleDetails = [];
        this.vehicleCondition = [];
        this.vehicleInfo = [];
      },
      getElement() {
        const formFeatures = document.getElementById("features-content-Id");
        const labels = formFeatures.querySelectorAll("label.feature-label");

        for (let i = 0; i < labels.length; i++) {
          const labelText = labels[i].textContent;
          const parent = labels[i].parentNode.parentNode;

          if (labelText == "Wheels") {
            parent.style.display = "block";
          }
          if (labelText == "Tires") {
            parent.style.display = "block";
          }
          if (labelText == "Transmission") {
            parent.style.display = "block";
          }
          if (labelText == "Seating capacity") {
            parent.style.display = "block";
          }
          if (labelText == "Heated front seats") {
            parent.style.display = "block";
          }
          if (labelText == "Power driver seat controls") {
            parent.style.display = "block";
          }
          if (labelText == "Door locks") {
            parent.style.display = "block";
          }
          if (labelText == "All-in-one key") {
            parent.style.display = "block";
          }
          if (labelText == "First-row sunroof") {
            parent.style.display = "block";
          }
        }

        const formTech = document.getElementById("tech-content-Id");
        const labelsTech = formTech.querySelectorAll("label.tech-label");

        for (let i = 0; i < labelsTech.length; i++) {
          const labelsTechText = labelsTech[i].textContent;
          const parentTech = labelsTech[i].parentNode.parentNode;

          if (labelsTechText == "Engine Cylinders") {
            parentTech.style.display = "block";
          }
          if (labelsTechText == "Engine Cylinders") {
            parentTech.style.display = "block";
          }
          if (labelsTechText == "cc") {
            parentTech.style.display = "block";
          }
        }

        for (let i = 1; i < this.groupedInteriorColorsData.length; i++) {
          document.querySelector(
            "#interiorId .col-md-6:nth-of-type(" + (i + 1) + ")"
          ).style.display = "none";
        }
        for (let i = 1; i < this.groupedExteriorColorsData.length; i++) {
          document.querySelector(
            "#exteriorId .col-md-6:nth-of-type(" + (i + 1) + ")"
          ).style.display = "none";
        }
      },

      async handleImageUpload(event) {
        const curFiles = event.target.files;

        for (const file of curFiles) {
          if (file.size <= 3000000) {
            this.isImageValid = true;
            this.imageError = false;

            const base64Url = await this.readFileAsDataURL(file);
            this.images.push(base64Url);
          } else {
            this.isImageValid = false;
            this.imageError = true;
            this.imageErrorMessage = "Image size must be less than 3MB";
          }
        }

        if (this.images.length < 4) {
          this.isImageValid = false;
          this.imageError = true;
          this.imageErrorMessage = "Need atleast 4  image for upload";
        } else if (this.images.length > 10) {
          this.isImageValid = false;
          this.imageError = true;
          this.imageErrorMessage = "Maximum 10 images allowed for upload";
        }
      },

      readFileAsDataURL(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = () => {
            const base64Url = reader.result;
            resolve(base64Url);
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        });
      },

      handleImageDelete(index) {
        this.images.splice(index, 1);
        this.imageList = this.images;
      },

      /////
      selectOptionStructural() {
        this.optionStructuralErrorMessage = "";
        this.optionStructural = "";
        this.InputKeyStructural++;
        if (
          this.selectedOptionStructural !== "" &&
          this.optionStructural === ""
        ) {
          this.optionStructuralError = true;
          this.isImageValid = false;
          this.optionStructuralErrorMessage = "Please select a image";
        } else if (
          this.selectedOptionStructural === "" &&
          this.optionStructural !== ""
        ) {
          this.optionStructuralError = true;
          this.isImageValid = false;
          this.optionStructuralErrorMessage = "Please select a condition";
        }
      },

      onChangeOptionStructural(event) {
        this.optionStructuralErrorMessage = "";
        this.optionStructural = "";

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (file.size <= 3000000) {
            this.isImageValid = true;
            this.optionStructural = reader.result;
            this.optionStructuralError = false;
          } else if (file.size > 3000000) {
            this.optionStructuralErrorMessage =
              "Selected image exceeds the size limit of 3MB.";

            this.isImageValid = false;
            this.optionStructuralError = true;
          } else if (this.selectedOptionStructural === "") {
            this.optionStructuralError = true;
            this.isImageValid = false;
            this.optionStructuralErrorMessage = "Please select a condition";
          } else {
            this.optionStructuralError = false;
            this.isImageValid = true;
          }
        };
      },
      updateImageUrlStructural(newImageUrl) {
        this.optionStructural = newImageUrl;
      },
      selectOptionWindshield() {
        this.optionWindshieldErrorMessage = "";
        this.optionWindshield = "";
        this.InputKeyWindshield++;

        if (
          this.selectedOptionWindshield !== "" &&
          this.optionWindshield === ""
        ) {
          this.optionWindshieldError = true;
          this.isImageValid = false;
          this.optionWindshieldErrorMessage = "Please select a image";
        } else if (
          this.selectedOptionWindshield === "" &&
          this.optionWindshield !== ""
        ) {
          this.optionWindshieldError = true;
          this.isImageValid = false;
          this.optionWindshieldErrorMessage = "Please select a condition";
        }
      },

      onChangeOptionWindshield(event) {
        this.optionWindshieldErrorMessage = "";
        this.optionWindshield = "";

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (file.size <= 3000000) {
            this.isImageValid = true;
            this.optionWindshield = reader.result;
            this.optionWindshieldError = false;
          } else if (file.size > 3000000) {
            this.isImageValid = false;
            this.optionWindshieldError = true;
            this.optionWindshieldErrorMessage =
              "Selected image exceeds the size limit of 3MB.";
          } else if (this.selectedOptionWindshield === "") {
            this.optionWindshieldError = true;
            this.isImageValid = false;
            this.optionWindshieldErrorMessage = "Please select a condition";
          } else {
            this.optionWindshieldError = false;
            this.isImageValid = true;
          }
        };
      },
      updateImageUrlWindshield(newImageUrl) {
        this.optionWindshield = newImageUrl;
      },
      selectOptionTiers() {
        this.optionTiers = "";
        this.optionTiersErrorMessage = "";

        this.InputKeyTiers++;

        if (this.selectedOptionTiers !== "" && this.optionTiers === "") {
          this.optionTiersError = true;
          this.isImageValid = false;
          this.optionTiersErrorMessage = "Please select a image";
        } else if (this.selectedOptionTiers === "" && this.optionTiers !== "") {
          this.optionTiersError = true;
          this.isImageValid = false;
          this.optionTiersErrorMessage = "Please select a condition";
        }
      },

      onChangeOptionTiers(event) {
        this.optionTiersErrorMessage = "";
        this.optionTiers = "";

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (file.size <= 3000000) {
            this.isImageValid = true;
            this.optionTiers = reader.result;
            this.optionTiersError = false;
          } else if (file.size > 3000000) {
            this.isImageValid = false;
            this.optionTiersError = true;
            this.optionTiersErrorMessage =
              "Selected image exceeds the size limit of 3MB.";
          } else if (this.selectedOptionTiers === "") {
            this.optionTiersError = true;
            this.isImageValid = false;
            this.optionTiersErrorMessage = "Please select a condition";
          } else {
            this.optionTiersError = false;
            this.isImageValid = true;
          }
        };
      },
      updateImageUrlTiers(newImageUrl) {
        this.optionTiers = newImageUrl;
      },
      selectOptionAirbags() {
        this.optionAirbags = "";
        this.optionAirbagsErrorMessage = "";

        this.InputKeyAirbags++;

        if (this.selectedOptionAirbags !== "" && this.optionAirbags === "") {
          this.optionAirbagsError = true;
          this.isImageValid = false;
          this.optionAirbagsErrorMessage = "Please select a image";
        } else if (
          this.selectedOptionAirbags === "" &&
          this.optionAirbags !== ""
        ) {
          this.optionAirbagsError = true;
          this.isImageValid = false;
          this.optionAirbagsErrorMessage = "Please select a condition";
        }
      },

      onChangeOptionAirbags(event) {
        this.optionAirbagsErrorMessage = "";
        this.optionAirbags = "";

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (file.size <= 3000000) {
            this.isImageValid = true;
            this.optionAirbags = reader.result;
            this.optionAirbagsError = false;
          } else if (file.size > 3000000) {
            this.isImageValid = false;
            this.optionAirbagsError = true;
            this.optionAirbagsErrorMessage =
              "Selected image exceeds the size limit of 3MB.";
          } else if (this.selectedOptionWindshield === "") {
            this.optionAirbagsError = true;
            this.isImageValid = false;
            this.optionAirbagsErrorMessage = "Please select a condition";
          } else {
            this.optionAirbagsError = false;
            this.isImageValid = true;
          }
        };
      },
      updateImageUrlAirbags(newImageUrl) {
        this.optionAirbags = newImageUrl;
      },

      selectOptionAirbagLight() {
        this.optionAirbagLight = "";
        this.optionAirbagLightErrorMessage = "";

        this.InputKeyAirbagLight++;

        if (
          this.selectedOptionAirbagLight !== "" &&
          this.optionAirbagLight === ""
        ) {
          this.optionAirbagLightError = true;
          this.isImageValid = false;
          this.optionAirbagLightErrorMessage = "Please select a image";
        } else if (
          this.selectedOptionAirbagLight === "" &&
          this.optionAirbagLight !== ""
        ) {
          this.optionAirbagLightError = true;
          this.isImageValid = false;
          this.optionAirbagLightErrorMessage = "Please select a condition";
        }
      },

      onChangeOptionAirbagLight(event) {
        this.optionAirbagLightErrorMessage = "";
        this.optionAirbagLight = "";

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (file.size <= 3000000) {
            this.isImageValid = true;
            this.optionAirbagLight = reader.result;
            this.optionAirbagLightError = false;
          } else if (file.size > 3000000) {
            this.isImageValid = false;
            this.optionAirbagLightError = true;
            this.optionAirbagLightErrorMessage =
              "Selected image exceeds the size limit of 3MB.";
          } else if (this.selectedOptionWindshield === "") {
            this.optionAirbagLightError = true;
            this.isImageValid = false;
            this.optionAirbagLightErrorMessage = "Please select a condition";
          } else {
            this.optionAirbagLightError = false;
            this.isImageValid = true;
          }
        };
      },
      updateImageUrlAirbagLight(newImageUrl) {
        this.optionAirbagLight = newImageUrl;
      },

      selectOptionAntiLock() {
        this.optionAntiLock = "";
        this.optionAntiLockErrorMessage = "";

        this.InputKeyAntiLock++;

        if (this.selectedOptionAntiLock !== "" && this.optionAntiLock === "") {
          this.optionAntiLockError = true;
          this.isImageValid = false;
          this.optionAntiLockErrorMessage = "Please select a image";
        } else if (
          this.selectedOptionAntiLock === "" &&
          this.optionAntiLock !== ""
        ) {
          this.optionAntiLockError = true;
          this.isImageValid = false;
          this.optionAntiLockErrorMessage = "Please select a condition";
        }
      },
      onChangeOptionAntiLock(event) {
        this.optionAntiLockErrorMessage = "";
        this.optionAntiLock = "";

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (file.size <= 3000000) {
            this.isImageValid = true;
            this.optionAntiLock = reader.result;
            this.optionAntiLockError = false;
          } else if (file.size > 3000000) {
            this.isImageValid = false;
            this.optionAntiLockError = true;
            this.optionAntiLockErrorMessage =
              "Selected image exceeds the size limit of 3MB.";
          } else if (this.selectedOptionAntiLock === "") {
            this.optionAntiLockError = true;
            this.isImageValid = false;
            this.optionAntiLockErrorMessage = "Please select a condition";
          } else {
            this.optionAntiLockError = true;
            this.isImageValid = false;
          }
        };
      },

      updateImageUrlAntiLock(newImageUrl) {
        this.optionAntiLock = newImageUrl;
      },
      selectOptionOdometer() {
        this.optionOdometer = "";
        this.optionOdometerErrorMessage = "";

        this.InputKeyOdometer++;

        if (this.selectedOptionOdometer !== "" && this.optionOdometer === "") {
          this.optionOdometerError = true;
          this.isImageValid = false;
          this.optionOdometerErrorMessage = "Please select a image";
        } else if (
          this.selectedOptionOdometer === "" &&
          this.optionOdometer !== ""
        ) {
          this.optionOdometerError = true;
          this.isImageValid = false;
          this.optionOdometerErrorMessage = "Please select a condition";
        }
      },

      onChangeOptionOdometer(event) {
        this.optionOdometerErrorMessage = "";
        this.optionOdometer = "";

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (file.size <= 3000000) {
            this.isImageValid = true;
            this.optionOdometer = reader.result;
            this.optionOdometerError = false;
          } else if (file.size > 3000000) {
            this.isImageValid = false;
            this.optionOdometerError = true;
            this.optionOdometerErrorMessage =
              "Selected image exceeds the size limit of 3MB.";
          } else if (this.selectedOptionOdometer === "") {
            this.optionOdometerError = true;
            this.isImageValid = false;
            this.optionOdometerErrorMessage = "Please select a condition";
          } else {
            this.optionOdometerError = true;
            this.isImageValid = false;
          }
        };
      },
      updateImageUrlOdometer(newImageUrl) {
        this.optionOdometer = newImageUrl;
      },

      selectOptionSuspension() {
        this.optionSuspension = "";
        this.optionSuspensionErrorMessage = "";

        this.InputKeySuspension++;

        if (
          this.selectedOptionSuspension !== "" &&
          this.optionSuspension === ""
        ) {
          this.optionSuspensionError = true;
          this.isImageValid = false;
          this.optionSuspensionErrorMessage = "Please select a image";
        } else if (
          this.selectedOptionSuspension === "" &&
          this.optionSuspension !== ""
        ) {
          this.optionSuspensionError = true;
          this.isImageValid = false;
          this.optionSuspensionErrorMessage = "Please select a condition";
        }
      },

      onChangeOptionSuspension(event) {
        this.optionSuspensionErrorMessage = "";
        this.optionSuspension = "";

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (file.size <= 3000000) {
            this.isImageValid = true;
            this.optionSuspension = reader.result;
            this.optionSuspensionError = false;
          } else if (file.size > 3000000) {
            this.isImageValid = false;
            this.optionSuspensionError = true;
            this.optionSuspensionErrorMessage =
              "Selected image exceeds the size limit of 3MB.";
          } else if (this.selectedOptionSuspension === "") {
            this.optionSuspensionError = true;
            this.isImageValid = false;
            this.optionSuspensionErrorMessage = "Please select a condition";
          } else {
            this.optionSuspensionError = false;
            this.isImageValid = false;
          }
        };
      },

      updateImageUrlSuspension(newImageUrl) {
        this.optionSuspension = newImageUrl;
      },

      selectOptionEmission() {
        this.optionEmission = "";
        this.optionEmissionErrorMessage = "";

        this.InputKeyEmission++;

        if (this.selectedOptionEmission !== "" && this.optionEmission === "") {
          this.optionEmissionError = true;
          this.isImageValid = false;
          this.optionEmissionErrorMessage = "Please select a image";
        } else if (
          this.selectedOptionEmission === "" &&
          this.optionEmission !== ""
        ) {
          this.optionEmissionError = true;
          this.isImageValid = false;
          this.optionEmissionErrorMessage = "Please select a condition";
        }
      },

      onChangeOptionEmission(event) {
        this.optionEmissionErrorMessage = "";
        this.optionEmission = "";

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (file.size <= 3000000) {
            this.isImageValid = true;
            this.optionEmission = reader.result;
            this.optionEmissionError = false;
          } else if (file.size > 3000000) {
            this.isImageValid = false;
            this.optionEmissionError = true;
            this.optionEmissionErrorMessage =
              "Selected image exceeds the size limit of 3MB.";
          } else if (this.selectedOptionWindshield === "") {
            this.optionEmissionError = true;
            this.isImageValid = false;
            this.optionEmissionErrorMessage = "Please select a condition";
          } else {
            this.optionEmissionError = false;
            this.isImageValid = true;
          }
        };
      },

      updateImageUrlEmission(newImageUrl) {
        this.optionEmission = newImageUrl;
      },

      onImageUploadChange() {
        const input = document.querySelector(".allImageUploade");
        const preview = document.querySelector(".preview");

        while (preview.firstChild) {
          preview.removeChild(preview.firstChild);
        }

        const curFiles = input.files;
        if (curFiles.length === 0) {
          this.isImageValid = false;
          const para = document.createElement("p");
          para.textContent = "No files currently selected for upload";
          preview.appendChild(para);
        } else if (curFiles.length < 4) {
          this.isImageValid = false;
          const para = document.createElement("p");
          para.textContent = "Need atleast 4  image for upload";
          para.style.color = "red";
          preview.appendChild(para);
        } else if (curFiles.length > 9) {
          this.isImageValid = false;
          const para = document.createElement("p");
          para.textContent = "Can't upload more than 10 images";
          para.style.color = "red";
          preview.appendChild(para);
        } else {
          const list = document.createElement("div");
          list.className = "row list";
          preview.appendChild(list);
          const imageList = [];

          for (const file of curFiles) {
            const listItem = document.createElement("div");
            listItem.className = "col-md-6 list-item";
            listItem.style.backgroundImage = "url(" + file.name + ")";
            const para = document.createElement("p");

            if (this.validFileType(file)) {
              para.textContent = `File name ${file.name
                }, file size ${this.returnFileSize(file.size)}.`;
              const image = document.createElement("img");
              image.src = URL.createObjectURL(file);

              listItem.appendChild(image);
              listItem.appendChild(para);

              // Create a FileReader object
              const reader = new FileReader();

              // Set the onload event handler
              reader.onload = () => {
                // Get the base64 data URL
                const base64Url = reader.result;

                // Add the base64 URL to the imageList array
                imageList.push(base64Url);

                // Check if all images have been converted to base64
                if (imageList.length === curFiles.length) {
                  // Set the imageList data property
                  this.imageList = imageList;
                }
              };

              // Read the file as a data URL
              reader.readAsDataURL(file);

              this.isImageValid = true;
            } else {
              para.textContent = `${file.name}: Not a valid file type.Please update your selection.`;
              para.style.color = "red"; // Add a red text color style
              listItem.appendChild(para);
              this.isImageValid = false;
            }
            if (file.size > 3000000) {
              this.isImageValid = false;
              para.textContent = `${file.name}: file size ${this.returnFileSize(
                file.size
              )}. File size should be below 3MB. Please update your selection.`;
              para.style.color = "red"; // Add a red text color style
            }

            list.appendChild(listItem);
          }

          this.imageList = curFiles;
        }
      },

      validFileType(file) {
        return this.fileTypes.includes(file.type);
      },
      returnFileSize(number) {
        if (number < 1024) {
          return `${number} bytes`;
        } else if (number >= 1024 && number < 1048576) {
          return `${(number / 1024).toFixed(1)} KB`;
        } else if (number >= 1048576) {
          return `${(number / 1048576).toFixed(1)} MB`;
        }
      },

      onVinSearch(values) {
        this.loading = true;

        this.getAuctionTimeSlot();
        let vendorId = this.userinfo.sellerId;
        const whitespaceRemoved = values.VIN.replace(/\s/g, "");
        userService
          .getVINDetails(whitespaceRemoved, vendorId) //sellerId
          .then((response) => {
            this.loading = false;
            if (
              response.data.Data.result1[0].vinDetails ==
              "VIN is already registered with us"
            ) {
              this.invalidVinMsg = response.data.Data.result1[0].vinDetails;
              this.isVinValid = true;
              this.loading = false;

              setTimeout(() => {
                this.isVinValid = false;
                this.invalidVinMsg = "";
              }, 5000);
            } else if (
              response.data.Data.result1[0].vinDetails ==
              "VIN information not available"
            ) {
              this.invalidVinMsg = response.data.Data.result1[0].vinDetails;
              this.isVinValid = true;
              this.loading = false;

              setTimeout(() => {
                this.isVinValid = false;
                this.invalidVinMsg = "";
              }, 5000);
            } else {
              this.vehiclDetailList = JSON.parse(
                response.data.Data.result1[0].vinDetails
              );

              setTimeout(() => {
                this.getElement();
              }, 1000);

              this.vinInfo = this.vehiclDetailList;

              this.vehicleInfo = this.vehiclDetailList.vehicles;

              this.features = this.vehiclDetailList.features;

              this.exteriorColors = this.vehiclDetailList.exteriorColors;

              this.groupedExteriorColorsData = this.exteriorColors;

              this.interiorColors = this.vehiclDetailList.interiorColors;

              this.groupedInteriorColorsData = this.interiorColors;

              this.techSpecs = this.vehiclDetailList.techSpecs;

              this.vehicleDetailsData = response.data.Data.result1[0];

              this.vehicleSellingPrice = this.vehicleDetailsData.vehPrice;
              this.auctionStartPrice = this.vehicleDetailsData.auctionStartPrice;

              this.dealerNotes = this.vehicleDetailsData.vehdescription;
              this.kmClocked = this.vehicleDetailsData.kmClocked;

              if (this.vehicleDetailsData.vehicleCondition) {
                let vehicleConditionList =
                  this.vehicleDetailsData.vehicleCondition.split(",");
                this.selectedOptionStructural = vehicleConditionList[0]
                  ? vehicleConditionList[0]
                  : "";
                this.selectedOptionWindshield = vehicleConditionList[1]
                  ? vehicleConditionList[1]
                  : "";
                this.selectedOptionTiers = vehicleConditionList[2]
                  ? vehicleConditionList[2]
                  : "";
                this.selectedOptionAirbags = vehicleConditionList[3]
                  ? vehicleConditionList[3]
                  : "";
                this.selectedOptionAirbagLight = vehicleConditionList[4]
                  ? vehicleConditionList[4]
                  : "";

                this.selectedOptionAntiLock = vehicleConditionList[5]
                  ? vehicleConditionList[5]
                  : "";
                this.selectedOptionOdometer = vehicleConditionList[6]
                  ? vehicleConditionList[6]
                  : "";
                this.selectedOptionSuspension = vehicleConditionList[7]
                  ? vehicleConditionList[7]
                  : "";
                this.selectedOptionEmission = vehicleConditionList[8]
                  ? vehicleConditionList[8]
                  : "";

                this.optionStructural = this.vehicleDetailsData
                  .vehicleConditionImg1
                  ? Config.BASE_URL_IMG +
                  this.vehicleDetailsData.vehicleConditionImg1
                  : "";
                this.optionWindshield = this.vehicleDetailsData
                  .vehicleConditionImg2
                  ? Config.BASE_URL_IMG +
                  this.vehicleDetailsData.vehicleConditionImg2
                  : "";
                this.optionTiers = this.vehicleDetailsData.vehicleConditionImg3
                  ? Config.BASE_URL_IMG +
                  this.vehicleDetailsData.vehicleConditionImg3
                  : "";

                this.optionAirbags = this.vehicleDetailsData.vehicleConditionImg4
                  ? Config.BASE_URL_IMG +
                  this.vehicleDetailsData.vehicleConditionImg4
                  : "";
                this.optionAirbagLight = this.vehicleDetailsData
                  .vehicleConditionImg5
                  ? Config.BASE_URL_IMG +
                  this.vehicleDetailsData.vehicleConditionImg5
                  : "";

                this.optionAntiLock = this.vehicleDetailsData.vehicleConditionImg6
                  ? Config.BASE_URL_IMG +
                  this.vehicleDetailsData.vehicleConditionImg6
                  : "";
                this.optionOdometer = this.vehicleDetailsData.vehicleConditionImg7
                  ? Config.BASE_URL_IMG +
                  this.vehicleDetailsData.vehicleConditionImg7
                  : "";
                this.optionSuspension = this.vehicleDetailsData
                  .vehicleConditionImg8
                  ? Config.BASE_URL_IMG +
                  this.vehicleDetailsData.vehicleConditionImg8
                  : "";
                this.optionEmission = this.vehicleDetailsData.vehicleConditionImg9
                  ? Config.BASE_URL_IMG +
                  this.vehicleDetailsData.vehicleConditionImg9
                  : "";
              }

              if (this.vehicleDetailsData) {
                this.damgeImageUrl1 = this.vehicleDetailsData.damgeImageUrl1
                  ? Config.BASE_URL_IMG + this.vehicleDetailsData.damgeImageUrl1
                  : "";
                this.damgeImageUrl2 = this.vehicleDetailsData.damgeImageUrl2
                  ? Config.BASE_URL_IMG + this.vehicleDetailsData.damgeImageUrl2
                  : "";
                this.damgeImageUrl3 = this.vehicleDetailsData.damgeImageUrl3
                  ? Config.BASE_URL_IMG + this.vehicleDetailsData.damgeImageUrl3
                  : "";
                this.damgeImageUrl4 = this.vehicleDetailsData.damgeImageUrl4
                  ? Config.BASE_URL_IMG + this.vehicleDetailsData.damgeImageUrl4
                  : "";
              }
              //console.log(  this.damgeImage1 );

              if (this.vehicleDetailsData.vehImage1) {
                this.images.push(
                  Config.BASE_URL_IMG + this.vehicleDetailsData.vehImage1
                );
              }
              if (this.vehicleDetailsData.vehImage2) {
                this.images.push(
                  Config.BASE_URL_IMG + this.vehicleDetailsData.vehImage2
                );
              }
              if (this.vehicleDetailsData.vehImage3) {
                this.images.push(
                  Config.BASE_URL_IMG + this.vehicleDetailsData.vehImage3
                );
              }
              if (this.vehicleDetailsData.vehImage4) {
                this.images.push(
                  Config.BASE_URL_IMG + this.vehicleDetailsData.vehImage4
                );
              }
              if (this.vehicleDetailsData.vehImage5) {
                this.images.push(
                  Config.BASE_URL_IMG + this.vehicleDetailsData.vehImage5
                );
              }
              if (this.vehicleDetailsData.vehImage6) {
                this.images.push(
                  Config.BASE_URL_IMG + this.vehicleDetailsData.vehImage6
                );
              }
              if (this.vehicleDetailsData.vehImage7) {
                this.images.push(
                  Config.BASE_URL_IMG + this.vehicleDetailsData.vehImage7
                );
              }
              if (this.vehicleDetailsData.vehImage8) {
                this.images.push(
                  Config.BASE_URL_IMG + this.vehicleDetailsData.vehImage8
                );
              }
              if (this.vehicleDetailsData.vehImage8) {
                this.images.push(
                  Config.BASE_URL_IMG + this.vehicleDetailsData.vehImage8
                );
              }
              if (this.vehicleDetailsData.vehImage9) {
                this.images.push(
                  Config.BASE_URL_IMG + this.vehicleDetailsData.vehImage9
                );
              }
              if (this.vehicleDetailsData.vehImage10) {
                this.images.push(
                  Config.BASE_URL_IMG + this.vehicleDetailsData.vehImage10
                );
              }

              if (response.data.Data.result1[0].auctionTimeSlotId) {
                this.selectedSlotId =
                  response.data.Data.result1[0].auctionTimeSlotId;
              }
            }
          })
          .catch((e) => {
            console.log(e);
            this.invalidVinMsg = "! Oops somthing went wrong";
            this.isVinValid = true;
            this.loading = false;

            setTimeout(() => {
              this.isVinValid = false;
              this.invalidVinMsg = "";
            }, 3000);
          });
      },
      getAuctionTimeSlot() {
        userService
          .getAuctionTimeSlot()
          .then((response) => {
            console.log("auction time slot", response.data.Data);

            this.auctionSlotsList = response.data.Data;
            // console.log(this.VechType);
          })
          .catch((e) => {
            console.log(e);
            // this.$router.push("/Auction").catch(() => {
            //   "details/auctionbyname";
            // });
          });
      },

      submitForm() {
        // Validate all tabs before submitting
        this.loadingUpload = true;
        for (let i = 0; i <= this.lastTabIndex; i++) {
          if (!this.validateTab(i)) {
            this.activeTab = i;
            this.updateProgressBar();
            this.loadingUpload = false;
            return;
          }
        }

        this.modalAuctionSlot = true;

        //console.log("Form Data:", this.formData);
      },

      saveTabData(index) {
        this.isvinActive = 0;
        this.saveBtnLoader = true;
        if (index == 4) {
          if (this.images.length < 1) {
            this.imageError = true;
            this.saveBtnLoader = false;
            //console.log(this.imageError);
            this.imageErrorMessage = "Need atleast 1 image for upload";
            this.isImageValid = false;
          } else if (this.images.length > 10) {
            this.saveBtnLoader = false;
            this.imageError = true;
            this.imageErrorMessage = "Maximum 10 images allowed for upload";
            this.isImageValid = false;
          } else {
            this.loadingAuctionSlot = false;
            this.onVehicleUploadSubmit(false);
          }
        } else {
          this.loadingAuctionSlot = false;
          this.onVehicleUploadSubmit(false);
        }
      },

      onVehicleUploadSubmit(isSubmitted) {
        let vehicleConditionNameArr = new Array(
          "Structural parts",
          "Windshield",
          "Tiers",
          "Airbags",
          "AirbagLight",
          "Anti-lock Breaks",
          "Odometer",
          "Suspension",
          "Emission"
        );
        let vehicleConditionNameString = vehicleConditionNameArr.join(",");

        let vehicleConditionArr = [];

        vehicleConditionArr.push(this.selectedOptionStructural);

        vehicleConditionArr.push(this.selectedOptionWindshield);

        vehicleConditionArr.push(this.selectedOptionTiers);

        vehicleConditionArr.push(this.selectedOptionAirbags);

        vehicleConditionArr.push(this.selectedOptionAirbagLight);

        vehicleConditionArr.push(this.selectedOptionAntiLock);

        vehicleConditionArr.push(this.selectedOptionOdometer);

        vehicleConditionArr.push(this.selectedOptionSuspension);

        vehicleConditionArr.push(this.selectedOptionEmission);

        let vehicleConditionString = vehicleConditionArr.join(",");

        // console.log("Condition Name : ", vehicleConditionNameString);
        // console.log("Selected conditions : ", vehicleConditionString);

        let vehicleImage1 = "";
        let vehicleImage2 = "";
        let vehicleImage3 = "";
        let vehicleImage4 = "";
        let vehicleImage5 = "";
        let vehicleImage6 = "";
        let vehicleImage7 = "";
        let vehicleImage8 = "";
        let vehicleImage9 = "";
        let vehicleImage10 = "";
        let vehicleImage11 = "";
        let vehicleImage12 = "";
        let vehicleImage13 = "";
        let vehicleImage14 = "";
        let vehicleImage15 = "";
        let vehicleImage16 = "";
        let vehicleImage17 = "";
        let vehicleImage18 = "";
        let vehicleImage19 = "";

        if (typeof this.images[0] !== "undefined") {
          if (
            this.images[0].includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage1 = this.images[0].split("canadabidadmin/").pop();
          } else {
            vehicleImage1 = this.images[0] ? this.images[0] : "";
          }
        } else {
          vehicleImage1 = this.images[0] ? this.images[0] : "";
        }

        if (typeof this.images[1] !== "undefined") {
          if (
            this.images[1].includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage2 = this.images[1].split("canadabidadmin/").pop();
          } else {
            vehicleImage2 = this.images[1] ? this.images[1] : "";
          }
        } else {
          vehicleImage2 = this.images[1] ? this.images[1] : "";
        }

        if (typeof this.images[2] !== "undefined") {
          if (
            this.images[2].includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage3 = this.images[2].split("canadabidadmin/").pop();
          } else {
            vehicleImage3 = this.images[2] ? this.images[2] : "";
          }
        } else {
          vehicleImage3 = this.images[2] ? this.images[2] : "";
        }

        if (typeof this.images[3] !== "undefined") {
          if (
            this.images[3].includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage4 = this.images[3].split("canadabidadmin/").pop();
          } else {
            vehicleImage4 = this.images[3] ? this.images[3] : "";
          }
        } else {
          vehicleImage4 = this.images[3] ? this.images[3] : "";
        }

        if (typeof this.images[4] !== "undefined") {
          if (
            this.images[4].includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage5 = this.images[4].split("canadabidadmin/").pop();
          } else {
            vehicleImage5 = this.images[4] ? this.images[4] : "";
          }
        } else {
          vehicleImage5 = this.images[4] ? this.images[4] : "";
        }

        if (typeof this.images[5] !== "undefined") {
          if (
            this.images[5].includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage6 = this.images[5].split("canadabidadmin/").pop();
          } else {
            vehicleImage6 = this.images[5] ? this.images[5] : "";
          }
          vehicleImage6 = this.images[5].split("canadabidadmin/").pop();
        } else {
          vehicleImage6 = this.images[5] ? this.images[5] : "";
        }

        if (typeof this.images[6] !== "undefined") {
          if (
            this.images[6].includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage7 = this.images[6].split("canadabidadmin/").pop();
          } else {
            vehicleImage7 = this.images[6] ? this.images[6] : "";
          }
        } else {
          vehicleImage7 = this.images[6] ? this.images[6] : "";
        }

        if (typeof this.images[7] !== "undefined") {
          if (
            this.images[7].includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage8 = this.images[7].split("canadabidadmin/").pop();
          } else {
            vehicleImage8 = this.images[7] ? this.images[7] : "";
          }
        } else {
          vehicleImage8 = this.images[7] ? this.images[7] : "";
        }

        if (typeof this.images[8] !== "undefined") {
          if (
            this.images[8].includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage9 = this.images[8].split("canadabidadmin/").pop();
          } else {
            vehicleImage9 = this.images[8] ? this.images[8] : "";
          }
        } else {
          vehicleImage9 = this.images[8] ? this.images[8] : "";
        }

        if (typeof this.images[9] !== "undefined") {
          if (
            this.images[9].includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage10 = this.images[9].split("canadabidadmin/").pop();
          } else {
            vehicleImage10 = this.images[9] ? this.images[9] : "";
          }
        } else {
          vehicleImage10 = this.images[9] ? this.images[9] : "";
        }

        if (
          typeof this.optionStructural !== "undefined" &&
          this.selectedOptionStructural !== ""
        ) {
          if (
            this.optionStructural.includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage11 = this.optionStructural.split("canadabidadmin/").pop();
          } else {
            vehicleImage11 = this.optionStructural ? this.optionStructural : "";
          }
        } else {
          vehicleImage11 = this.optionStructural ? this.optionStructural : "";
        }

        if (
          typeof this.optionWindshield !== "undefined" &&
          this.selectedOptionWindshield !== ""
        ) {
          if (
            this.optionWindshield.includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage12 = this.optionWindshield.split("canadabidadmin/").pop();
          } else {
            vehicleImage12 = this.optionWindshield ? this.optionWindshield : "";
          }
        } else {
          vehicleImage12 = this.optionWindshield ? this.optionWindshield : "";
        }
        if (
          typeof this.optionTiers !== "undefined" &&
          this.selectedOptionTiers !== ""
        ) {
          if (
            this.optionTiers.includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage13 = this.optionTiers.split("canadabidadmin/").pop();
          } else {
            vehicleImage13 = this.optionTiers ? this.optionTiers : "";
          }
        } else {
          vehicleImage13 = this.optionTiers ? this.optionTiers : "";
        }
        if (
          typeof this.optionAirbags !== "undefined" &&
          this.selectedOptionAirbags !== ""
        ) {
          if (
            this.optionAirbags.includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage14 = this.optionAirbags.split("canadabidadmin/").pop();
          } else {
            vehicleImage14 = this.optionAirbags ? this.optionAirbags : "";
          }
        } else {
          vehicleImage14 = this.optionAirbags ? this.optionAirbags : "";
        }
        if (
          typeof this.optionAirbagLight !== "undefined" &&
          this.selectedOptionAirbagLight !== ""
        ) {
          if (
            this.optionAirbagLight.includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage15 = this.optionAirbagLight
              .split("canadabidadmin/")
              .pop();
          } else {
            vehicleImage15 = this.optionAirbagLight ? this.optionAirbagLight : "";
          }
        } else {
          vehicleImage15 = this.optionAirbagLight ? this.optionAirbagLight : "";
        }

        if (
          typeof this.optionAntiLock !== "undefined" &&
          this.selectedOptionAntiLock !== ""
        ) {
          if (
            this.optionAntiLock.includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage16 = this.optionAntiLock.split("canadabidadmin/").pop();
          } else {
            vehicleImage16 = this.optionAntiLock ? this.optionAntiLock : "";
          }
        } else {
          vehicleImage16 = this.optionAntiLock ? this.optionAntiLock : "";
        }

        if (
          typeof this.optionOdometer !== "undefined" &&
          this.selectedOptionOdometer !== ""
        ) {
          if (
            this.optionOdometer.includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage17 = this.optionOdometer.split("canadabidadmin/").pop();
          } else {
            vehicleImage17 = this.optionOdometer ? this.optionOdometer : "";
          }
        } else {
          vehicleImage17 = this.optionOdometer ? this.optionOdometer : "";
        }

        if (
          typeof this.optionSuspension !== "undefined" &&
          this.selectedOptionSuspension !== ""
        ) {
          if (
            this.optionSuspension.includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage18 = this.optionSuspension.split("canadabidadmin/").pop();
          } else {
            vehicleImage18 = this.optionSuspension ? this.optionSuspension : "";
          }
        } else {
          vehicleImage18 = this.optionSuspension ? this.optionSuspension : "";
        }
        if (
          typeof this.optionEmission !== "undefined" &&
          this.selectedOptionEmission !== ""
        ) {
          if (
            this.optionEmission.includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            vehicleImage19 = this.optionEmission.split("canadabidadmin/").pop();
          } else {
            vehicleImage19 = this.optionEmission ? this.optionEmission : "";
          }
        } else {
          vehicleImage19 = this.optionEmission ? this.optionEmission : "";
        }

        // Imagestr = str.split(":").pop();

        if (this.damgeImageUrl1 !== "") {
          if (
            this.damgeImageUrl1.includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            this.damgeImage1 = this.damgeImageUrl1.split("canadabidadmin/").pop();
          } else {
            this.damgeImage1 = this.damgeImageUrl1;
          }
        }
        if (this.damgeImageUrl2 !== "") {
          if (
            this.damgeImageUrl2.includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            this.damgeImage2 = this.damgeImageUrl2.split("canadabidadmin/").pop();
          } else {
            this.damgeImage2 = this.damgeImageUrl2;
          }
        }
        if (this.damgeImageUrl3 !== "") {
          if (
            this.damgeImageUrl3.includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            this.damgeImage3 = this.damgeImageUrl3.split("canadabidadmin/").pop();
          } else {
            this.damgeImage3 = this.damgeImageUrl3;
          }
        }
        if (this.damgeImageUrl4 !== "") {
          if (
            this.damgeImageUrl4.includes(
              "canadabidadmin/assets/images/uploads/productimages"
            )
          ) {
            this.damgeImage4 = this.damgeImageUrl4.split("canadabidadmin/").pop();
          } else {
            this.damgeImage4 = this.damgeImageUrl4;
          }
        }

        let payload = {
          sp: "insVINDetails",
          sellerId: this.userinfo.sellerId,
          vehPrice:
            this.vehicleSellingPrice == "" || this.vehicleSellingPrice == null
              ? 0
              : this.vehicleSellingPrice,
          vehMinPrice: 0,
          auctionStartPrice:
            this.auctionStartPrice == "" || this.auctionStartPrice == null
              ? 0
              : this.auctionStartPrice,
          vehicleRegNo: "",
          vehdescription: this.dealerNotes == "" ? "" : this.dealerNotes,
          vin: this.vinInfo.vinSubmitted == "" ? "" : this.vinInfo.vinSubmitted,
          VINInfo: this.vinInfo,
          kmClocked:
            this.kmClocked == "" || this.kmClocked == null ? 0 : this.kmClocked,
          status: 1,
          vehImage1: vehicleImage1,
          vehImage2: vehicleImage2,
          vehImage3: vehicleImage3,
          vehImage4: vehicleImage4,
          vehImage5: vehicleImage5,
          vehImage6: vehicleImage6,
          vehImage7: vehicleImage7,
          vehImage8: vehicleImage8,
          vehImage9: vehicleImage9,
          vehImage10: vehicleImage10,
          vehicleConditionName: vehicleConditionNameString
            ? vehicleConditionNameString
            : "",
          vehicleCondition: vehicleConditionString ? vehicleConditionString : "",
          vehicleConditionImg1: vehicleImage11,
          vehicleConditionImg2: vehicleImage12,
          vehicleConditionImg3: vehicleImage13,
          vehicleConditionImg4: vehicleImage14,
          vehicleConditionImg5: vehicleImage15,
          vehicleConditionImg6: vehicleImage16,
          vehicleConditionImg7: vehicleImage17,
          vehicleConditionImg8: vehicleImage18,
          vehicleConditionImg9: vehicleImage19,
          damageImageUrl1: this.damgeImage1,
          damageImageUrl2: this.damgeImage2,
          damageImageUrl3: this.damgeImage3,
          damageImageUrl4: this.damgeImage4,
          isvinActive: this.isvinActive,
          auctionSlot: this.selectedSlotId,
        };

        //console.log(payload);
        userService
          .insVINDetails(payload)
          .then((response) => {
            this.msgSuccess = response.data.Message;
            let vehicleStatus = "";

            if (response.data.Data[0]) {
              vehicleStatus = response.data.Data[0].vehicleStatus;
              this.saveBtnLoader = false;

              this.$toast.warning(vehicleStatus, {
                position: "top",
                duration: 3000,
                queue: true,
              });
            } else {
              this.saveBtnLoader = false;
              this.$toast.success("Successfully updated", {
                position: "top",
                duration: 3000,
                queue: true,
              });
              this.modalAuctionSlot = false;
              if (isSubmitted) {
                this.redirectTo("VehicleList");
              } else {
                this.redirectTo("VehicleUpload");
              }
            }
          })
          .catch((e) => {
            console.log(e);
            this.loadingAuctionSlot = false;
            this.saveBtnLoader = false;
            this.loadingUpload = false;
            this.$toast.error("!Oops sothing went wrong. try again", {
              position: "top",
              duration: 3000,
              queue: true,
            });
          });

        this.loading = false;
      },
      redirectTo(toPath) {
        if (toPath == "VehicleUpload") {
          setTimeout(() => {
            /// this.onVinSearch();
            this.$router.push({
              name: "VehicleUpload",
              query: {
                vin: this.vinInfo.vinSubmitted,
              },
            });
          }, 2000);
        } else if (toPath == "VehicleList") {
          setTimeout(() => {
            this.$router.push("VehicleList");
          }, 2000);
        }
      },

      isEmpty() {
        var indexValue = null;

        let isValid = true;

        //vin info
        let vImage = document
          .getElementsByClassName("vinDecodedDetails")[0]
          .querySelectorAll("input");
        for (let name of vImage) {
          name.addEventListener("change", function () {
            removeErrorMessage(name);
          });
          //  console.log(name.value);
          if (name.value == "") {
            //  console.log(name);

            let prevNode = name.previousSibling;

            // Get the previous text content
            let prevText = "";
            name.classList.add("errorClass");
            if (prevNode == null) {
              prevText =
                name.parentNode.parentNode.parentNode.previousSibling.textContent.trim();
            } else {
              prevText = prevNode.textContent.trim();
            }
            let errorClass = name.nextSibling;

            // Remove the existing validation error if it exists
            if (errorClass && errorClass.classList.contains("validationError")) {
              errorClass.remove();
            }
            const newDiv = document.createElement("div");
            newDiv.textContent =
              "* Please enter the " + prevText.toLocaleLowerCase();
            newDiv.style.color = "red";
            newDiv.className = "validationError";
            // Insert the new div after the existing div
            name.insertAdjacentHTML("afterend", newDiv.outerHTML);

            isValid = false;
            indexValue = 0;
            // Set focus to the first input field with an error
            name.focus();

            // Exit the loop after the first error is found
            // break;
          } else {
            // Clear the validation error message if the input field contains a value
            let errorDiv = name.nextElementSibling;
            if (errorDiv && errorDiv.classList.contains("validationError")) {
              errorDiv.remove();
            }
            name.classList.remove("errorClass");
          }
        }
        //features
        let vinDecodedFeatures = document
          .getElementsByClassName("vinDecodedFeatures")[0]
          .querySelectorAll("input");
        for (let name of vinDecodedFeatures) {
          //  console.log(name.value);
          name.addEventListener("change", function () {
            removeErrorMessage(name);
          });
          if (name.value == "") {
            //  console.log(name);

            let prevNode = name.previousSibling;

            // Get the previous text content
            let prevText = "";
            name.classList.add("errorClass");
            if (prevNode == null) {
              prevText =
                name.parentNode.parentNode.parentNode.previousSibling.textContent.trim();
            } else {
              prevText = prevNode.textContent.trim();
            }
            let errorClass = name.nextSibling;

            // Remove the existing validation error if it exists
            if (errorClass && errorClass.classList.contains("validationError")) {
              errorClass.remove();
            }
            const newDiv = document.createElement("div");
            newDiv.textContent =
              "* Please enter the " + prevText.toLocaleLowerCase();
            newDiv.style.color = "red";
            newDiv.className = "validationError";
            // Insert the new div after the existing div
            name.insertAdjacentHTML("afterend", newDiv.outerHTML);

            isValid = false;
            indexValue = 1;
            // Set focus to the first input field with an error
            name.focus();

            // Exit the loop after the first error is found
            // break;
          } else {
            // Clear the validation error message if the input field contains a value
            let errorDiv = name.nextElementSibling;
            if (errorDiv && errorDiv.classList.contains("validationError")) {
              errorDiv.remove();
            }
            name.classList.remove("errorClass");
          }
        }

        ///techspecification
        let techspecification = document
          .getElementsByClassName("techspecification")[0]
          .querySelectorAll("input");
        for (let name of techspecification) {
          //  console.log(name.value);
          name.addEventListener("change", function () {
            removeErrorMessage(name);
          });
          if (name.value == "") {
            //  console.log(name);

            let prevNode = name.previousSibling;

            // Get the previous text content
            let prevText = "";
            name.classList.add("errorClass");
            if (prevNode == null) {
              prevText =
                name.parentNode.parentNode.parentNode.previousSibling.textContent.trim();
            } else {
              prevText = prevNode.textContent.trim();
            }
            let errorClass = name.nextSibling;

            // Remove the existing validation error if it exists
            if (errorClass && errorClass.classList.contains("validationError")) {
              errorClass.remove();
            }
            const newDiv = document.createElement("div");
            newDiv.textContent =
              "* Please enter the " + prevText.toLocaleLowerCase();
            newDiv.style.color = "red";
            newDiv.className = "validationError";
            // Insert the new div after the existing div
            name.insertAdjacentHTML("afterend", newDiv.outerHTML);

            isValid = false;
            indexValue = 2;
            // Set focus to the first input field with an error
            name.focus();

            // Exit the loop after the first error is found
            // break;
          } else {
            // Clear the validation error message if the input field contains a value
            let errorDiv = name.nextElementSibling;
            if (errorDiv && errorDiv.classList.contains("validationError")) {
              errorDiv.remove();
            }
            name.classList.remove("errorClass");
          }
        }

        let dealernote = document.getElementsByClassName("dealernote")[0];
        let selectElement = dealernote.querySelector("select");
        selectElement.addEventListener("change", function () {
          removeErrorMessage(selectElement);
        });
        // Validate the select element
        if (selectElement.value === "") {
          selectElement.classList.add("errorClass");

          let errorDiv = selectElement.nextElementSibling;
          if (errorDiv && errorDiv.classList.contains("validationError")) {
            errorDiv.remove();
          }
          const newDiv = document.createElement("div");
          newDiv.textContent = "* Please select an auction slot";
          newDiv.style.color = "red";
          newDiv.className = "validationError";
          selectElement.insertAdjacentHTML("afterend", newDiv.outerHTML);

          isValid = false;
          indexValue = 5;
        } else {
          let errorDiv = selectElement.nextElementSibling;
          if (errorDiv && errorDiv.classList.contains("validationError")) {
            errorDiv.remove();
          }
          selectElement.classList.remove("errorClass");
        }

        let textareaElements = dealernote.querySelectorAll("textarea");

        // Validate the textarea elements
        for (let textarea of textareaElements) {
          textarea.addEventListener("change", function () {
            removeErrorMessage(textarea);
          });
          if (textarea.value === "") {
            textarea.classList.add("errorClass");

            let prevNode = textarea.previousSibling;
            let prevText = "";
            if (prevNode === null) {
              prevText =
                textarea.parentNode.parentNode.parentNode.previousSibling.textContent.trim();
            } else {
              prevText = prevNode.textContent.trim();
            }

            let errorDiv = textarea.nextElementSibling;
            if (errorDiv && errorDiv.classList.contains("validationError")) {
              errorDiv.remove();
            }
            const newDiv = document.createElement("div");
            newDiv.textContent = "* Please enter the " + prevText.toLowerCase();
            newDiv.style.color = "red";
            newDiv.className = "validationError";
            textarea.insertAdjacentHTML("afterend", newDiv.outerHTML);

            isValid = false;
            indexValue = 5;
            textarea.focus();

            //  break;
          } else {
            let errorDiv = textarea.nextElementSibling;
            if (errorDiv && errorDiv.classList.contains("validationError")) {
              errorDiv.remove();
            }
            textarea.classList.remove("errorClass");
          }
        }

        function removeErrorMessage(element) {
          let errorDiv = element.nextElementSibling;
          if (errorDiv && errorDiv.classList.contains("validationError")) {
            errorDiv.remove();
          }
          element.classList.remove("errorClass");
        }

        if (this.images.length < 4) {
          this.isImageValid = false;
          isValid = false;
          indexValue = 4;
          this.imageError = true;
          this.imageErrorMessage = "Need atleast 4  image for upload";
        } else if (this.images.length > 10) {
          isValid = false;
          indexValue = 4;
          this.isImageValid = false;
          this.imageError = true;
          this.imageErrorMessage = "Maximum 10 images allowed for upload";
        }

        var obj = {
          indexNumber: indexValue,
          isValidStatus: isValid,
        };

        return obj;
      },
    },
  };
</script>
<style scoped>
  .vinDecoderCard {
    margin: 50px 0px !important;
  }

  .vech-up-jump {
    text-transform: uppercase;
  }

  .vehicleUploadImage {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0rem 0rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.85;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    display: block;
  }

  label {
    margin-top: 4px !important;
  }

  .btn-vehicle-upload {
    text-align: end;
    margin: 35px 5px 0px 0px;
    padding: 10px 34px;
  }

  .hidden-box {
    display: block;
  }

  .drop-down svg {
    width: 24px !important;
    /* margin: 100px auto; */
    display: flex;
    /* flex-direction: column; */
    gap: 22px;
    margin-left: 80%;
  }

  /* .label-container {
  display: flex;
} */
  .subSectionInner12 {
    background-color: red;
    margin-top: 40px;
  }

  .errorClass {
    border-color: red;
  }

  .clickable-label-container {
    display: flex;
    gap: 0px 8px;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 8px 22px;
  }

  .vin-not-found {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fdeded;
    background-clip: border-box;
    border: 1px solid rgba(155, 0, 0, 0.13);
    border-radius: 0.25rem;
  }

  .vin-not-found p {
    margin-top: 15px;
    text-transform: uppercase;
  }

  .modalMarkerImage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
  }

  .markerError {
    color: red;
  }

  a.vech-up-jump.active {
    background-color: #5294e2;
    color: white !important;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
  }

  .success-popup {
  position: fixed;
  top: -50px; /* Start above the top of the page */
  left: 50%;
  transform: translateX(-50%);
  background-color: yellow;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.2em;
  z-index: 1000; /* Make sure it appears above other content */
  transition: top 0.5s;
}

.slide-down-enter-active, .slide-down-leave-active {
  transition: top 0.5s;
}

.slide-down-enter, .slide-down-leave-to /* .slide-down-leave-active in <2.1.8 */ {
  top: 20px;
}


</style>